#root{
  background-color: #359fab;
}
/* 基础样式 */
.app-container {
  font-family: Arial, sans-serif;
  margin: 0 auto;
  max-width: 900px;
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  color: #3885b6;
}

/* 左右内容区域 */
.info-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  width: 100%;
  flex-wrap: wrap;  /* 让内容在小屏幕时自动换行 */
}

/* 左侧代币信息部分 */
.left-column {
  flex: 4;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  box-sizing: border-box;  /* 包含 padding 和 border */
}

/* 右侧账户信息部分 */
.right-column {
  flex: 6;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  box-sizing: border-box;
  word-wrap: break-word; /* 自动换行 */
  overflow-wrap: break-word; /* 自动换行 */
  white-space: normal; /* 确保文本不会溢出 */
}

.vesting-info {
  background-color: #f8f9fa; /* 轻微的背景色 */
  margin-top: 20px;
}

/* 标题居中 */
.left-column h3, .right-column h3 {
  text-align: center;
}

/* 账户信息样式 */
.account-info {
  font-size: 16px;
  color: #555;
}

h2 {
  margin-top: 30px;
  color: #333;
}

/* 输入框和按钮容器 */
.input-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.input-field {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  gap:20px;
}

.button {
  background-color: #3885b6;
  color: white;
  border: none;
  padding: 8px 16px;  /* 默认按钮大小（适中） */
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #3885b6;
}

/* 余额结果样式 */
.balance-result {
  background-color: #e1f7e1;
  padding: 15px;
  border-radius: 5px;
  margin-top: 20px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}

.vesting-info-container {
  margin: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  overflow-x: auto;  /* 启用横向滚动条 */
}

table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;  /* 自动调整表格布局 */
  word-wrap: break-word; /* 允许单词换行 */
}

th, td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
  word-break: break-word; /* 防止长单词或地址溢出 */
  max-width: 200px;  /* 设置最大宽度，避免表格过宽 */
  overflow-wrap: break-word; /* 确保内容溢出时换行 */
}

th {
  background-color: #f2f2f2;
  white-space: nowrap; /* 防止标题过长换行 */
}

tbody tr:hover {
  background-color: #f1f1f1;
}


/* 媒体查询 - 小屏幕（移动设备） */
@media (max-width: 768px) {
  .info-container {
    flex-direction: column;  /* 列布局 */
  }

  .left-column, .right-column {
    flex: none;  /* 取消 flex 的比例分配 */
    width: 100%; /* 每个区域宽度为 100% */
    margin-bottom: 20px; /* 每个部分之间有间隔 */
  }

  /* 调整标题样式 */
  h2, h3 {
    font-size: 20px;
  }

  .button {
    width: 100%;  /* 按钮在小屏幕上宽度 100% */
    padding: 12px;  /* 增大按钮大小，适应触控 */
  }

  .balance-result {
    font-size: 16px;  /* 适应小屏幕的字体 */
    padding: 10px;
  }
}

/* 媒体查询 - 更小屏幕（例如手机） */
@media (max-width: 480px) {
  h1 {
    font-size: 24px;  /* 调整标题字体 */
  }

  h2, h3 {
    font-size: 18px;
  }

  /* 确保 .left-column 和 .right-column 的宽度适配 */
  .left-column, .right-column {
    width: 100%;  /* 强制每一列宽度为 100% */
    padding: 15px; /* 减少内边距以避免超出边界 */
  }

  .input-field {
    font-size: 14px;  /* 小屏幕调整输入框字体 */
    padding: 8px;
  }

  .button {
    padding: 10px;
    font-size: 14px;
  }

  .balance-result {
    font-size: 16px;  /* 适应小屏幕的字体 */
    padding: 10px;
  }

  /* 确保页面内容不溢出 */
  .app-container {
    padding: 10px;  /* 缩小容器内边距 */
    max-width: 100%;  /* 保证容器最大宽度为 100% */
  }
  
}
